import React from 'react';
import axios from "axios";

// import { tips } from '../util';
import { config } from '../config/config';

axios.defaults.baseURL = config.BACKEND_URL;

export const restApi = {
  getChainsTokens: async () => {
    try {
      const result = await axios.post('/api/getChainsTokens')
      return result.data
    } catch (err) {
      console.log(err.message)
      return []
    }
  },

  getTokensInfo: async () => {
    try {
      const result = await axios.post('/api/getTokensInfo')
      return result.data
    } catch (err) {
      console.log(err.message)
      return null
    }
  },

  getHistory: async (address: string) => {
    try {
      const result = await axios.post('/api/getHistorys', {
        userAddr: address
      })

      return result.data
    } catch (err) {
      console.log(err.message)
      return []
    }
  }
}