import React from "react";
import { useEffect, useState } from 'react'

import { useGlobalContext } from "provider";
import { getSubString } from "../../util";

export const Header = () => {
  const [state, { checkConnected }] = useGlobalContext()
  const [account, setAccount] = useState<string>('')

  useEffect(() => {
    let tempaccount = ''
    if (state.account) {
      tempaccount = getSubString(state.account)
    }

    if (tempaccount !== account) {
      setAccount(tempaccount)
    }
  }, [state.account])

  const connectWallet = () => {
    if (state.walletStatus === 2) {
      return
    }

    checkConnected()
  }

  return (
    <div className="flex flex-row justify-between items-center py-20">
      <div className="flex flex-row items-center cursor-pointer">
        <span className="text-1.8r font-bold opacity-80">Bridge</span>
      </div>

      <div className="flex flex-row items-center">
        <div onClick={connectWallet}
          className="h-50 w-120 flex flex-col items-center justify-center px-10 rounded-lg bg-bgBright cursor-pointer opacity-100 hover:opacity-75 select-none"
        >
          {account ? account : 'Connect'}
        </div>
      </div>
    </div>
  )
}