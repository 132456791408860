import React from "react";
import { useEffect, useState } from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


import './bridge.scss'
import { copyObject } from "../../util";
import { TxHistorys } from './txHistorys';
import { useGlobalContext } from "../../provider";
import { coins } from "../../assets/img.config";

export const Bridge = () => {
  const [state, { makeDeposit }] = useGlobalContext()
  // calculate dest amount

  const [gasFee, setGasFee] = useState<number>(0)
  const [destNetwork, SetDestnetwork] = useState<ChainObject | null>(null)

  useEffect(() => {
    let checkNetwork = state.chainList.find((network: ChainObject) => (
      network.chainID === state.destChain
    ))

    if (checkNetwork) {
      SetDestnetwork(checkNetwork)
    } else SetDestnetwork(null)
  }, [state.destChain, state.chainList])

  useEffect(() => {
    let tempFee = 0

    if (destNetwork) {
      let checkToken = state.tokensList.find((token: TokenObject) => (
        token.chainID === destNetwork.chainID
      ))

      if (checkToken) {
        tempFee = checkToken.gasFee
      }
    }

    if (gasFee !== tempFee) {
      setGasFee(tempFee)
    }
  }, [destNetwork, state.tokensList])

  return (
    <div className="component-wrapper py-80">
      <div className="bridge-container">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <div className="text-1.5r font-medium">
              Bridge
            </div>

            <div className="text-1r opacity-50">
              Send your assets across chains.
            </div>
          </div>

          {/* <div className="flex flex-row items-center">
            <div className="flex flex-row gap-5 items-center bg-bgBright px-15 py-10 rounded-lg cursor-pointer opacity-75 hover:opacity-100">
              <SettingsIcon />

              <span className="text-1.2r">
                Settings
              </span>
            </div>
          </div> */}
        </div>

        <div className="relative flex flex-col gap-25 bg-bgBase p-25 rounded-lg">
          <div className="flex flex-col gap-10 bg-bgLight p-15 rounded-lg">
            <div className="flex flex-row gap-5 justify-between items-center">
              <span className="opacity-50">Origin</span>

              <ChainsHeader type='origin'
                chainID={state.originChain}
              />
            </div>

            <TokensSelection type='origin'
              chainID={state.originChain}
              tokenSymbol={state.tokenSymbol}
            />
          </div>

          <div className="flex flex-col gap-10 bg-bgLight p-15 rounded-lg">
            <div className="flex flex-row gap-5 justify-between items-center">
              <span className="opacity-50">Dest.</span>

              <ChainsHeader type='dest'
                chainID={state.destChain}
              />
            </div>

            <TokensSelection type='dest'
              chainID={state.destChain}
              tokenSymbol={state.tokenSymbol}
            />
          </div>

          <div className="flex flex-col gap-5">
            {destNetwork && (
              <div className="flex flex-row justify-between text-colorUnActive">
                <span className="text-1r">
                  Gas fee price is ${gasFee.toFixed(4)}
                </span>
              </div>
            )}
          </div>

          <div onClick={makeDeposit}
            className="h-50 flex flex-col items-center justify-center bg-bgBright px-10 rounded-lg cursor-pointer opacity-75 hover:opacity-100 select-none"
          >
            {state.walletStatus === 0 && (
              <span>Install Metamask</span>
            )}

            {state.walletStatus === 1 && (
              <span>Connect Wallet</span>
            )}

            {state.walletStatus === 2 && (
              <span>
                {state.originAmount === 0 ? (
                  'Enter amount to bridge'
                ) : ('Exchange')}
              </span>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-5 text-1r">
            <span className="opacity-50">Need help? Read</span>
            <span className="opacity-80 hover:opacity-100 cursor-pointer">this guide</span>
          </div>

          <div className="flex flex-row gap-5 opacity-50 hover:opacity-100 cursor-pointer">
            <AnalyticsOutlinedIcon />
            <span>Explorer</span>
          </div>
        </div>
      </div>

      {state.account && (
        <TxHistorys />
      )}
    </div>
  )
}

const ChainsHeader = ({ chainID, type }: ChainsHeaderProps) => {
  const [state, { dispatch, connectToMetamask, switchNetwork }] = useGlobalContext()

  const [search, setSearch] = useState<string>('')
  const [menuState, setMenuState] = useState<boolean>(false)
  const [chains, setchains] = useState<ChainObject[] | null>(null)
  const [activeChain, setActiveChain] = useState<ChainObject | null>(null)

  const openMenu = () => { setMenuState(true) }
  const closeMenu = () => { setMenuState(false) }

  useEffect(() => {
    const tempList = copyObject(state.chainList)
    const tempChain = tempList.find((chain: ChainObject) => (
      chain.chainID === chainID
    ))

    if (tempChain) {
      const filterList = tempList.filter((chain: ChainObject) => (
        chain.chainID !== chainID
      ))

      setActiveChain(tempChain)
      setchains(filterList.slice(0, 5))
    } else {
      setchains(null)
      setActiveChain(null)
    }
  }, [chainID, state.chainList])

  const changeChain = async (chain: ChainObject) => {
    if (chain.chainID === chainID) return
    if (!!menuState) closeMenu()

    if (type === 'origin' || (type === 'dest' && chain.chainID === state.originChain)) {
      let tempChainID = chain.chainID

      if (chain.chainID === state.originChain) {
        tempChainID = state.destChain
      }

      if (state.walletStatus !== 2) {
        const checkConnect = await connectToMetamask(tempChainID)
        if (!checkConnect) return
      } else {
        let checkChain = await switchNetwork(tempChainID)
        if (!checkChain) return
      }
    }

    if (type === 'origin') {
      if (chain.chainID === state.destChain) {
        dispatch({
          type: 'destChain',
          payload: state.originChain
        })
      }

      dispatch({
        type: 'originChain',
        payload: chain.chainID
      })
    }

    if (type === 'dest') {
      if (chain.chainID === state.originChain) {
        dispatch({
          type: 'originChain',
          payload: state.destChain
        })
      }

      dispatch({
        type: 'destChain',
        payload: chain.chainID
      })
    }

    dispatch({ type: "balance", payload: 0 })
    dispatch({ type: 'originAmount', payload: 0 })
  }

  return (
    <div className="chainHeader-wrapper flex flex-row gap-10">
      {!!chains?.length && (
        <div className="chainHeader-container flex flex-row gap-10 items-center">
          {!!activeChain && (
            <div className="chainHeader-active h-30 flex flex-row gap-5 justify-center items-center rounded-full">
              <img alt=''
                src={coins[activeChain.chainID]}
                className="h-full rounded-full"
              />

              <span className="text-0.9r opacity-80">
                {activeChain.name}
              </span>
            </div>
          )}

          {chains?.map((item: ChainObject, key: number) => (
            <div key={key}
              className="chainHeader-item w-28 h-28 flex flex-row justify-center items-center border-2 border-bgBright rounded-full bg-bgLight cursor-pointer"
              onClick={() => { changeChain(item) }}
            >
              <img alt=''
                src={coins[item.chainID]}
                className="rounded-full"
              />
            </div>
          ))}
        </div>
      )}

      <div onClick={openMenu}
        className="w-32 h-32 flex flex-row justify-center items-center bg-bgBright rounded-full cursor-pointer"
      >
        <KeyboardArrowDownIcon className="text-30 opacity-75 hover:opacity-50" />
      </div>

      <MenuWrapper onClose={closeMenu}
        searchHolder='Search by asset, name, or chainID'
        onSearch={() => { setSearch('') }}
        menuState={menuState}
        search={search}
      >
        {state.chainList.map((chain: ChainObject, key: number) => (
          <div key={key}
            onClick={() => { changeChain(chain) }}
            className={`bridge-menu-item ${chainID === chain.chainID && 'active-item'}`}
          >
            <img alt=''
              src={coins[chain.chainID]}
              className="w-45 h-45 rounded-full"
            />

            <div className="flex-1 flex flex-col opacity-80">
              <span className="text-1.3r font-semibold">{chain.name}</span>
              <span className="text-1r opacity-50">{chain.layer}</span>
            </div>
          </div>
        ))}
      </MenuWrapper>
    </div>
  )
}

const TokensSelection = (props: TokensSelectionProps) => {
  const { type, tokenSymbol, chainID } = props
  const [state, { dispatch, connectToMetamask, addToken }] = useGlobalContext()

  const [search, setSearch] = useState<string>('')
  const [menuState, setMenuState] = useState<boolean>(false)
  const [activeToken, setActiveToken] = useState<TokenObject | null>(null)
  const [activeChain, setActiveChain] = useState<ChainObject | null>(null)

  const openMenu = () => { setMenuState(true) }
  const closeMenu = () => { setMenuState(false) }

  useEffect(() => {
    const tempList = copyObject(state.chainList)
    const tempChain = tempList.find((chain: ChainObject) => (
      chain.chainID === chainID
    ))

    if (tempChain) {
      const tempTokens = copyObject(tempChain.tokens)
      const tempToken = tempTokens.find((token: TokenObject) => (
        token.symbol === tokenSymbol
      ))

      setActiveToken(tempToken)
      setActiveChain(tempChain)

    } else {
      setActiveToken(null)
      setActiveChain(null)
    }
  }, [tokenSymbol, chainID, state.chainList])

  const tokenChange = async (token: TokenObject) => {
    if (token.symbol === tokenSymbol) return
    if (!!menuState) closeMenu()

    if (state.walletStatus !== 2) {
      const checkConnect = await connectToMetamask(state.originChain)

      if (!checkConnect) return
    }

    dispatch({ type: "balance", payload: 0 })
    dispatch({ type: 'originAmount', payload: 0 })
    dispatch({ type: 'tokenSymbol', payload: token.symbol })
  }

  const amountChange = async ({ target }: any) => {
    let value = Number(target.value) || 0

    if (value !== state.originAmount) {
      dispatch({ type: 'originAmount', payload: value })
    }
  }

  const setMaxAmount = async () => {
    if (state.balance !== state.originAmount) {
      dispatch({ type: 'originAmount', payload: state.balance })
    }
  }

  // calculate dest amount
  const [destAmount, setDestAmount] = useState<number>(0)

  useEffect(() => {
    if (type !== 'dest') return

    let tempAmount = 0
    let checkToken = state.tokensList.find((token: TokenObject) => (
      token.chainID === chainID && token.symbol === tokenSymbol
    ))

    if (checkToken) {
      let gasAmount = checkToken.gasFee / checkToken.price
      tempAmount = state.originAmount - gasAmount
      if (tempAmount < 0) tempAmount = 0
    }

    if (destAmount !== tempAmount) {
      setDestAmount(tempAmount)
    }
  }, [state])

  return (
    <>
      <div className="flex flex-row gap-10 items-center justify-between p-10 border border-bgBright rounded-xl">
        <div className="flex-1 flex flex-row gap-10">
          <div onClick={openMenu}
            className="min-w-140 flex flex-row gap-5 items-center p-12 bg-bgBright border border-unset rounded-lg cursor-pointer hover:bg-bgActive hover:border hover:border-borderActive hover:opacity-75"
          >
            {activeToken && (
              <>
                <img alt=""
                  src={coins[activeToken.symbol]}
                  className="w-28 h-28"
                />

                <div className="text-1.2r font-semibold opacity-90">
                  {activeToken.name}
                </div>

                <KeyboardArrowDownIcon className="text-25 opacity-80" />
              </>
            )}
          </div>


          {(activeToken && type === 'origin') && (
            <div className="flex-1 flex flex-col">
              <div className="flex-1 flex flex-col items justify-center">
                <input type="number"
                  onChange={amountChange}
                  value={state.originAmount}
                  className="default-input w-full h-full text-1.5r opacity-70"
                />
              </div>

              <div className="flex flex-row gap-5 items-center cursor-pointer">
                <span className="text-0.8r">{state.balance.toFixed(4)}</span>
                <span className="text-0.8r opacity-50">available</span>
              </div>
            </div>
          )}

          {(activeToken && type === 'dest') && (
            <div className="flex flex-row items-center">
              <span className="text-1.5r opacity-70">{destAmount.toFixed(4)}</span>
            </div>
          )}
        </div>

        {(activeToken && type === 'origin') && (
          <div className="flex flex-col items-center">
            <div onClick={setMaxAmount}
              className="px-10 py-5 bg-bgBright rounded-lg cursor-pointer hover:opacity-70"
            >
              <span className="text-0.9r font-semibold opacity-90">Max</span>
            </div>
          </div>
        )}
      </div>

      {(activeChain) && (
        <MenuWrapper onClose={closeMenu}
          searchHolder='Search by asset, name, or chainID'
          onSearch={() => { setSearch('') }}
          menuState={menuState}
          search={search}
        >
          {activeChain.tokens.map((token: TokenObject, key: number) => (
            <div className="flex flex-col gap-5" key={key}>
              {(!token?.isNative && type === 'origin') && (
                <div onClick={() => { addToken(token) }}
                  className="cursor-pointer hover:opacity-75"
                >
                  Add token
                </div>
              )}

              <div onClick={() => { tokenChange(token) }}
                className={`flex-1 bridge-menu-item ${tokenSymbol === token.symbol && 'active-item'}`}
              >
                <img alt=''
                  src={coins[token.symbol]}
                  className="w-45 h-45 rounded-full"
                />

                <div className="flex-1 flex flex-col">
                  <span className="text-1.3r font-semibold opacity-80">{token.name}</span>

                  <div className="flex flex-row gap-5">
                    <span className="text-1r opacity-50">{token.symbol} of </span>

                    <img alt=""
                      src={coins[activeChain.chainID]}
                      className="w-20 h-20 rounded-full"
                    />

                    <span className="text-1r opacity-80">{activeChain.name}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </MenuWrapper >
      )}
    </>
  )
}


// menu wrapper
const MenuWrapper = (props: MenuWrapperProps) => {
  const { menuState, onClose, children } = props
  const { search, searchHolder, onSearch } = props

  return (
    <div className={`bridge-menu-wrapper bg-bgBase ${menuState ? 'active-wrapper' : 'inActive-wrapper'}`}>
      <div className="bridge-menu-header rounded-t">
        <input type="text"
          value={search}
          onChange={onSearch}
          placeholder={searchHolder}
          className="default-input flex-1 text-1r opacity-70"
        />

        <div onClick={onClose}
          className="w-32 h-32 flex flex-row justify-center items-center bg-bgBright rounded-full cursor-pointer"
        >
          <KeyboardArrowDownIcon className="text-30 opacity-75 hover:opacity-50" />
        </div>
      </div>

      <div className="bridge-menu-body rounded-b-xl">
        <div className="bMenu-body-container">
          {children}
        </div>
      </div>
    </div>
  )
}