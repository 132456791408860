import React from 'react';
import { ethers } from "ethers";
import { useWallet } from 'use-wallet'
import { useEffect, useState } from 'react'
import { useReducer, useMemo } from "react";
import { createContext, useContext } from "react";
import detectEthereumProvider from '@metamask/detect-provider'
// import { useWeb3Modal } from '@web3modal/wagmi/react'
// import { useAccount, useDisconnect, useConnect, useNetwork, useSwitchNetwork, } from 'wagmi'
// import { mainnet, arbitrum } from 'wagmi/chains'

import { restApi } from './restApi';
import { copyObject, tips, toBigNum } from '../util';
import { addTokenToMetamask, getTokenBalance, switchNetwork } from './service';
import { getBridgeContract, getERC20Contract } from '../blockchain';

const metamaskStore = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'

const INIT_STATE: InitStateObject = {
  loading: false,
  chainList: [],
  tokensList: [],
  txHistorys: [],


  originAmount: 0,
  originChain: 56,
  destChain: 350,
  tokenSymbol: 'NOVA',

  // walletConnected: false,
  // walletChainId: null,
  walletStatus: 0, // 0: no metamask detect, 1: not connected, 2: connected

  balance: 0,
  account: null,
}

let tempStateData: InitStateObject = copyObject(INIT_STATE)

// create context
const GlobalContext = createContext<any>({});
const reducer = (state: InitStateObject, { type, payload }: ReducerObject) => (
  { ...state, [type]: payload }
)

// use contexts
export function useGlobalContext() {
  return useContext(GlobalContext);
}

export const Provider = ({ children }: any) => {
  const wallet: any = useWallet()
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  // const { address, connector, isDisconnected } = useAccount()
  // const { open, close } = useWeb3Modal()
  // const { disconnect, reset } = useDisconnect()
  // const { chain } = useNetwork()
  // const { switchNetwork } = useSwitchNetwork()
  // const chains = [mainnet]

  useEffect(() => {
    tempStateData = state
  }, [state])

  // get init infomation
  useEffect(() => {
    getTokensInfo()
    getChainsTokens()
  }, [])

  // get tokens price and all informations
  const [time, setTime] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(+ new Date())
      getTokensInfo()
      getHistorys()
    }, 5 * 1000)

    // Clear interval if component unmounted
    return () => clearInterval(interval)
  }, [time])

  useEffect(() => {
    if (state.account) {
      getHistorys()
    } else {
      dispatch({ type: "txHistorys", payload: [] })
    }
  }, [state.account])

  const getHistorys = async () => {
    let tempHistorys = []
    let address = tempStateData.account

    if (address) {
      tempHistorys = await restApi.getHistory(address)
    }

    dispatch({ type: "txHistorys", payload: tempHistorys })
  }

  const getTokensInfo = async () => {
    const tempInfo = await restApi.getTokensInfo()
    if (!tempInfo) return

    dispatch({ type: "tokensList", payload: tempInfo })
  }

  const getChainsTokens = async () => {
    const tempList = await restApi.getChainsTokens()
    dispatch({ type: "chainList", payload: tempList })
  }

  // get token balance
  useEffect(() => {
    getOriginBalance()
  }, [
    state.originChain,
    state.tokenSymbol,
    state.chainList,
    state.account
  ])

  const getOriginBalance = async () => {
    try {
      const checkChain = state.chainList.find((chain: ChainObject) => (
        chain.chainID === state.originChain
      ))

      let tempBalance = 0;
      if (checkChain && state.account) {
        const checkToken = checkChain.tokens.find((token: TokenObject) => (
          token.symbol === state.tokenSymbol
        ))

        if (checkToken) {
          tempBalance = await getTokenBalance(checkChain, checkToken, state.account)
        }
      }

      if (tempBalance !== state.balance) {
        dispatch({ type: "balance", payload: tempBalance })
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  // wallet section start
  useEffect(() => {
    if (wallet.status === 'connected') {
      dispatch({ type: "walletStatus", payload: 2 })
      dispatch({ type: "account", payload: wallet.account })
    } else {
      detect()
      dispatch({ type: "account", payload: null })
    }
  }, [wallet.status])

  const detect = async () => {
    let tempStatus = 0
    let provider = await detectEthereumProvider()

    if (provider) {
      tempStatus = 1
    }

    dispatch({
      type: "walletStatus",
      payload: tempStatus
    })
  }

  const connectToMetamask = async (chainID: number) => {
    return new Promise<boolean>((resolve) => {
      wallet.connect().then(async (res) => {
        try {
          //if metamask is connected and wallet is not connected ( chain error))
          if (wallet.chainId !== chainID) {
            await switchNetwork(chainID)
          }

          resolve(true)
        } catch (err: any) {
          console.log(err.message)
          tips('warning', err.message)

          resolve(false)
        }
      })
    })
  }

  const checkConnected = async () => {
    let tempState = false

    if (state.walletStatus === 0) {
      window.open(metamaskStore, "_blank");
    } else if (state.walletStatus === 1) {
      connectToMetamask(state.originChain)
    } else if (state.walletStatus === 2) {
      tempState = true
    }

    return tempState
  }
  // wallet sectio end

  // blockchain actions start
  const getSigner = async () => {
    const provider = new ethers.providers.Web3Provider(
      wallet.ethereum
    )

    return await provider.getSigner()
  }

  const approveToken = async (chain: ChainObject, token: TokenObject, signer: any) => {
    // token approve
    const erc20Contract = getERC20Contract(chain, token)

    let toAddr = chain.poolAddr
    let value = toBigNum(state.originAmount, token.decimal)
    let tx = await erc20Contract.connect(signer).approve(toAddr, value)
    await tx.wait()

    // token allowance
    await erc20Contract.connect(signer).allowance(state.account, toAddr);

    return tx;
  }

  const makeDeposit = async () => {
    let alertState = false
    dispatch({ type: "loading", payload: true })

    try {
      // check metamask connect state
      let checkState = await checkConnected()
      if (!checkState) {
        throw new Error("please wallet connect")
      }

      // check exchange token amount
      if (!state.originAmount) {
        tips('info', 'please enter exchange amount')
        throw new Error("please enter exchange amount")
      }

      // check switch chain id state
      const checkNetwork = await switchNetwork(state.originChain)
      if (!checkNetwork) {
        throw new Error("switch network error")
      }

      const originNetwork = state.chainList.find((network: ChainObject) => (
        network.chainID === state.originChain
      ))

      if (!originNetwork) {
        throw new Error("network not exists")
      }

      const originToken = originNetwork.tokens.find((token: TokenObject) => (
        token.symbol === state.tokenSymbol && token.chainID === state.originChain
      ))

      const destToken = state.tokensList.find((token: TokenObject) => (
        token.symbol === state.tokenSymbol && token.chainID === state.destChain
      ))

      if (!originToken || !destToken) {
        throw new Error("token not exists")
      }

      alertState = true
      const minium = (destToken.gasFee / destToken.price) * 2
      const maxValue = destToken.poolAmount / 2

      if (minium > state.originAmount) {
        throw new Error("Minium exchange amount is " + minium)
      }

      if (maxValue < state.originAmount) {
        throw new Error("max exchange amount is " + maxValue)
      }

      const signer = await getSigner()
      const bridgeContract = getBridgeContract(originNetwork)

      let tokenAddr = originToken.token
      let toChain = state.destChain.toString()
      let value = toBigNum(state.originAmount, originToken.decimal)

      if (originToken.isNative) {
        let tx = await bridgeContract.connect(signer).depositETH(value, toChain, { value: value })
        await tx.wait()

      } else {
        await approveToken(originNetwork, originToken, signer)
        let tx = await bridgeContract.connect(signer).deposit(tokenAddr, value, toChain)
        await tx.wait()
      }


      dispatch({ type: "loading", payload: false })
      tips("success", "please wait a few minutes");

      dispatch({ type: "loading", payload: false })
    } catch (err) {
      if (alertState) {
        tips('warning', err.message || 'exchange failed')
      }

      dispatch({ type: "loading", payload: false })
    }
  }
  // blockchain actions end

  const addToken = async (token: TokenObject) => {
    if (state.originChain !== token.chainID) {
      return
    }

    let result = await switchNetwork(token.chainID)

    if (result && !token.isNative) {
      addTokenToMetamask(token)
    }
  }

  return (
    <GlobalContext.Provider
      value={useMemo(() => [
        state, {
          dispatch,
          makeDeposit,
          switchNetwork,
          checkConnected,
          connectToMetamask,
          addToken,
        }
      ], [state])}
    >
      {children}
    </GlobalContext.Provider>
  )
}
