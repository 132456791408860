import React from "react";
import { useEffect, useState } from "react";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import './txHistorys.scss'
import { coins } from "assets/img.config";
import { useGlobalContext } from "provider";
import { getSubString } from "../../util";

interface FieldPropsObejct {
  chainID: number
  address: string
  amount: number
  symbol: string
}

export const TxHistorys = () => {
  const [state] = useGlobalContext()
  const [historyList, setHistoryList] = useState<TxHistoryObject[]>([])

  useEffect(() => {
    setHistoryList(state.txHistorys)
  }, [state.txHistorys])

  return (
    <div className="w-full flex flex-col gap-20">
      <span className="text-1.5r font-semibold opacity-70">Bridge Watcher</span>

      <div className="flex flex-col gap-20">
        {historyList.map((history: TxHistoryObject, key: number) => (
          <div className="flex flex-col gap-5" key={key}>
            <span className="opacity-50">{history.dateTime}</span>

            <div className="relative grid grid-cols-2 gap-30">
              <HistoryField address={history.from}
                symbol={history.tokenSymbol}
                chainID={history.fromChain}
                amount={history.amount}
              />
              <HistoryField address={history.from}
                symbol={history.tokenSymbol}
                chainID={history.toChain}
                amount={history.receiveAmount}
              />

              <div className="progress-wrapper">
                {history.progress === 'completed' && (
                  <KeyboardDoubleArrowRightIcon className="completed-icon" />
                )}

                {history.progress !== 'completed' && (
                  <AutorenewIcon className="pending-icon" />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const HistoryField = (props: FieldPropsObejct) => {
  const { chainID, address, amount, symbol } = props
  const [userAddr, setUserAddr] = useState<string>('')

  useEffect(() => {
    let tempAddr = getSubString(address)

    if (userAddr !== tempAddr) {
      setUserAddr(tempAddr)
    }
  }, [address])

  return (
    <div className="flex flex-row gap-10 px-10 py-5 items-center border border-bgBright rounded-lg cursor-pointer">
      <img alt=""
        src={coins[chainID]}
        className="w-20 h-20 rounded-full"
      />

      <div className="flex-1 flex flex-col">
        <span className="text-0.9r opacity-50">{userAddr}</span>

        <div className="flex flex-row gap-5 items-center">
          <span className="text-0.9r opacity-80">{amount.toFixed(4)} {symbol}</span>

          <img alt=""
            src={coins[symbol]}
            className="w-15 h-15 rounded-full"
          />
        </div>
      </div>
    </div>
  )
}