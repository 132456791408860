import React from 'react';

import { Bridge } from '../components/bridge/bridge';
import { Layouts } from '../components/layouts/layouts';

export const Home = () => {
  return (
    <Layouts>
      <Bridge />
    </Layouts>
  )
}