import coin_1 from './image/chain/1.webp'
import coin_10 from './image/chain/10.webp'
import coin_25 from './image/chain/25.webp'
import coin_56 from './image/chain/56.webp'
import coin_137 from './image/chain/137.webp'
import coin_250 from './image/chain/250.webp'
import coin_2222 from './image/chain/2222.webp'
import coin_42161 from './image/chain/42161.webp'
import coin_43114 from './image/chain/43114.webp'
import coin_4002 from './image/chain/4002.webp'
import coin_11155111 from './image/chain/11155111.webp'
import coin_421614 from './image/chain/421614.webp'
import coin_350 from './image/chain/350.webp'

import USDCIcon from './image/tokens/USDC.svg'
import USDTIcon from './image/tokens/USDT.svg'
import ETHIcon from './image/tokens/ETH.svg'
import BNBIcon from './image/tokens/BNB.png'
import NOVAIcon from './image/tokens/NOVA.svg'

export const coins = {
  // networks icon
  1: coin_1,
  10: coin_10,
  25: coin_25,
  56: coin_56,
  137: coin_137,
  250: coin_250,
  2222: coin_2222,
  42161: coin_42161,
  43114: coin_43114,



  // testnet
  351: coin_250,
  4002: coin_4002,
  421613: coin_1,
  11155111: coin_11155111,
  421614: coin_421614,
  350: coin_350,

  // tokens icon
  USDC: USDCIcon,
  USDT: USDTIcon,
  ETH: ETHIcon,
  BNB: BNBIcon,
  MGB: USDCIcon,
  SEP: ETHIcon,
  ASEP: ETHIcon,
  NOVA: NOVAIcon
}